@charset 'UTF-8';

// 1. Configuration and helpers
@import 'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

@import 'edit_site_container',
'popup_edit_site';

.breadcrumb-for-400 {
  display: none;
}

.btn+.side-info {
  margin-left: 1.4rem;
}

.side-info {
  @include set-font(1.3rem, 400, $color-gray-03);

  &:before {
    content: '*';
  }
}

.select-mode-options {
  @include set-flex(row, space-between, center);
  position: absolute;
  visibility: hidden;
  height: 0;
  padding: .6rem .3rem .6rem 1.6rem;
  border-radius: .6rem;
  background-color: #354056;
  transition: height .25s, padding .25s;

  &.expand {
    visibility: visible;
    top: -5.5rem;
    width: 100%;
    height: 4rem;
    z-index: 99;
  }

  .left-side {
    @include set-flex(row, flex-start, center);

    h2 {
      @include set-font(1.4rem, 400, $color-white, normal);
      margin-right: 2.4rem
    }

    .dropdown {
      margin: 0 .4rem;
      .btn-dropdown {
        height: 3.4rem;
        background-color: $color-white;
      }
    }

    .btn {
      @include set-font(1.2rem, 500, $color-white, normal);
      position: relative;
      min-width: 12rem;
      margin: 0 .4rem;
      border: 0px;
      background-color: #697387;

      &:active {
        // padding: 0 1.4rem;
        // border: 2px solid $color-primary;
        text-decoration: none;
      }
    }
  }

  .right-side {
    @include set-flex(row, flex-end, center);

    .btn-close {
      background: asset-image('ic_close.svg') center no-repeat;
    }

    .btn {
      @include set-font(1.25rem, 400, $color-gray-01, normal);
      position: relative;
      min-width: 12rem;
      margin: 0 .4rem;
      border-width: 1px;

      &:active {
        padding: 0 1.8rem;
        border: 3px solid $color-primary;
        text-decoration: none;
      }

      &.new:after {
        content: '';
        display: inline-block;
        width: .5rem;
        height: .5rem;
        margin-left: .3rem;
        border-radius: 50%;
        background-color: $color-primary;
        vertical-align: top;
      }
    }

    .group-tag-box {
      @include set-flex(row, flex-end, center);
      margin-left: 1rem;

      .tag {
        @include set-font(1.25rem, 400, $color-gray-01, normal);
        margin: 0 .4rem;
      }
    }
  }
}

.data-list-container {
  position: relative;
  .tag-box {
    .tag {
      @include set-font(1.3rem, 400, $color-gray-03, normal);
      display: inline-block;
      width: auto;
      height: auto;
      padding: 1rem 1.2rem;
      border-radius: .4rem;
      background-color: $color-white;
      border: 1px solid $color-border-03;
      white-space: pre-wrap;
      word-break: break-all;

      &:active {
        padding: 0.9rem 1rem;
      }
    }
  }

  .other-tags {
    @include set-font(1.4rem, 500, $color-dark-gray, 2.6rem);
    display: inline-block;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    border: 1px solid $color-gray;
    text-align: center;
  }

  .grid {
    .grid-elements {
      @include set-flex(row, flex-start, flex-start);
      flex-wrap: wrap;
      width: calc(100% + 2rem);
      // margin-top: 1.5rem;
      margin-left: -1rem;
      // 높이조절 관련
      // max-height: 71.5rem;
      overflow-y: auto;
    }

    &.hide {
      display: none;
    }

    .element {
      float: left;
      width: 35rem;
      margin: 0 .8rem;
      margin-bottom: 2rem;
      // padding: 1.6rem;
      padding: 2.6rem 1.6rem 1.6rem;
      border-radius: 1rem;
      border: 1px solid $color-border-03;
      min-height: 42rem;

      .element-select {
        @include set-flex(row, space-between, center);
        // margin-bottom: 1.6rem;
        margin-bottom: 2.8rem;
        .left-side {
          .checkbox {
            margin-bottom: 0;
            label {
              // display: block;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
        .right-side {
          .device-state {
            @include set-font(1.4rem, 400, $color-gray-01, normal);
            padding: .2rem 1rem;
            padding-right: 3rem;
            border-radius: 2rem;
            background: asset-image('ic_arrow_right2.svg') 95% center no-repeat;
            background-color: #eeeeee;
            text-align: center;
  
            &.expired {
              padding-right: 1rem;
              color: #333;
              background: none;
              background-color: #FEEFC7;
            }
            &.dday {
              padding-right: 1rem;
              color: #B32318;
              background: none;
              background-color: #FEE4E2;
            }
          }
        }
      }

      .image-box {
        position: relative;
        width: 100%;
        margin-bottom: 1.6rem;
        border-radius: .8rem;
        overflow: hidden;
        background-color: $color-light-gray;

        .dim {
          max-height: 16rem;
          overflow: hidden;
          a {
            display: block;
            max-height: 16rem;
            overflow: hidden;
            // background-color: rgba($color-gray-01, 0);
            background-color: #FFF;

            &:focus,
            &:hover {
              transition: background-color .2s;
              // background-color: rgba($color-gray-01, .4);
              background-color: #FFF;

              .icon2 {
                opacity: 1;
              }
            }

            &:focus-visible {
              border: 2px solid $color-black;
            }
          }

          .icon {
            z-index: 10;
            position: absolute;
            top: .8rem;
            right: .8rem;
            display: block;
            width: 2.8rem;
            height: 2.8rem;
            background: asset-image('ic_link.svg') center no-repeat;
          }
          .icon2 {
            z-index: 10;
            position: absolute;
            bottom: .8rem;
            right: .8rem;
            display: block;
            width: 1.9rem;
            height: 1.9rem;
            background: asset-image('full_size_icon.svg') center no-repeat;
            // opacity: 0; 웹접근성 수정
            opacity: 1;
          }
        }

        img {
          display: block;
          width: 100%;
        }

        .device-state {
          @include set-font(1.3rem, 400, $color-white, normal);
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 3;
          width: 100%;
          padding: .7rem;
          background-color: $color-dashboard-mint;
          text-align: center;
        }

        .device-error {
          @include set-font(1.2rem, 500, #D92D20, normal);
          position: absolute;
          bottom: .8rem;
          left: .8rem;
          z-index: 3;
          height: auto !important;
          padding: .4rem .4rem .4rem 2.8rem;
          border-radius: .4rem;
          background: asset-image('ic_warning_fill.svg') .6rem center no-repeat;
          // background-color: rgba(0, 0, 0, 0.30) !important;
          background-color: #FFF;
          span {
            padding-right: 1.3rem;
            background: asset-image('ic_arrow_right02.svg') 100% center no-repeat;
          }
        }
      }

      .element-middle {

        .title-box {
          .model_name {
            dt {
              @include set-font(1.6rem, 700, $color-gray-01, normal);
              display: inline-block;
            }
            dd {
              @include set-font(1.4rem, 400, $color-gray-02, 100%);
              display: inline-block;
              margin-left: .8rem;
              padding-left: .8rem;
              height: 1.5rem;
              border-left: 1px solid $color-border-03;
            }
          }
        }

        .device-model {
          margin-top: 1.6rem;
          dl {
            dt {
              @include set-font(1.4rem, 400, $color-gray-03, 140%);
              display: inline-block;
              width: 10rem;
            }
            dd {
              @include set-font(1.4rem, 500, $color-gray-01, 140%);
              display: inline-block;
              margin-left: 1.2rem;
            }
          }
        }

        .tag-box {
          margin-top: 1.5rem;
          @include set-flex(row, flex-start, center);

          .tag {
            @include set-flex-grow(1);
            margin-left: 0.6rem;
            padding: 0;
            text-align: center;
            // border: none;
            line-height: 3rem;

            &:first-of-type {
              margin-left: 0;
            }

            &:last-of-type {
              margin-right: 0.6rem;
            }
          }
        }
      }

      // .element middle

      .element-bottom {
        padding-top: 2.4rem;

        .btn-detail {
          // @include set-font(1.6rem, 500, $color-gray-01, 110%);
          @include set-font(1.2rem, 500, $color-gray-01, 110%);
          // padding: 1.4rem;
          padding: 1.05rem;
          width: 100%;
          height: auto;
          border-radius: .8rem;
          text-align: center;
          border: 1px solid $color-border-03;
          text-indent: 0;

          &.disabled-type {
            opacity: 0.7;
            pointer-events: none;
          }
        }
      }
    }

    .element-depth2 {
      padding-top: 2.6rem;

      .element-select {
        margin-bottom : 2.8rem;
      }

      .element-middle {
        .title-box {
          .model_name {
            dt {
              span {
                @include set-font(1.4rem, 400, $color-gray-02, 100%);
              }
            }
          }
        }

        .device-model {
          dl {
            dt {
              width: 100%;
            }
          }
        }
      }

      .image-box {
        .dim {
          a {
            max-height: none;
          }
        }
      }
    }
    
  }

  // .grid

  .table {
    &.hide {
      display: none;
    }
  }

  // .table

  // Set Table cell width //

  $tbody-height:57.6rem;
  $table-height:$tbody-height+3.6rem;

  .table-wrapper {
    &.device-list {
      &.horizontal-scroll {
        // table {
        //   min-width: 140.7rem;
        // }
      }

      table {

        // th,
        // td {
        //   &:nth-child(1) {
        //     width: 70px;
        //   }

        //   &:nth-child(2) {
        //     width: 100px;
        //   }

        //   &:nth-child(3) {
        //     width: 130px;
        //   }

        //   &:nth-child(4) {
        //     width: 10%;
        //   }

        //   &:nth-child(5) {
        //     width: 10%;
        //   }

        //   &:nth-child(6) {
        //     width: 8%;
        //   }

        //   &:nth-child(7) {
        //     width: 9%;
        //   }

        //   &:nth-child(8) {
        //     width: 120px;
        //   }

        //   &:nth-child(9) {
        //     width: 70px;
        //   }
        // }

        // th {
        //   .btn-sort,
        //   .text-block {
        //     height: 5.2rem;
        //   }
        // }

        tbody {
          // top: 5.4rem;
          // height: 56.6rem;
        }
      }

      &.select-mode {
        table {

          th,
          td {
            &:nth-child(1) {
              width: 50px;
            }

            // &:nth-child(2) {
            //   width: 70px;
            // }

            // &:nth-child(3) {
            //   width: 100px;
            // }

            // &:nth-child(4) {
            //   width: 130px;
            // }

            // &:nth-child(5) {
            //   width: 12%;
            // }

            // &:nth-child(6) {
            //   width: 10%;
            // }

            // &:nth-child(7) {
            //   width: 10%;
            // }

            // &:nth-child(8) {
            //   width: auto;
            // }

            // &:nth-child(9) {
            //   width: 10%;
            // }

            // &:nth-child(10) {
            //   width: 120px;
            // }

            // &:nth-child(11) {
            //   width: 120px;
            // }

            // &:nth-child(12) {
            //   width: 70px;
            // }
          }
        }
      }
    }

    &.device-list-test {
      &.horizontal-scroll {
        table {
          min-width: 140.7rem;
        }
      }

      table {

        th,
        td {
          &:nth-child(1) {
            width: 70px;
          }

          &:nth-child(2) {
            width: 100px;
          }

          &:nth-child(3) {
            width: 130px;
          }

          &:nth-child(4) {
            width: 10%;
          }

          &:nth-child(5) {
            width: 10%;
          }

          &:nth-child(6) {
            width: 8%;
          }

          &:nth-child(7) {
            width: 9%;
          }

          &:nth-child(8) {
            width: 120px;
          }

          &:nth-child(9) {
            width: 70px;
          }
        }

        // th {
        //   .btn-sort,
        //   .text-block {
        //     height: 5.2rem;
        //   }
        // }

        tbody {
          top: 5.4rem;
          height: 56rem;
        }
      }

      &.select-mode {
        table {

          th,
          td {
            &:nth-child(1) {
              width: 50px;
            }

            &:nth-child(2) {
              width: 80px;
            }

            &:nth-child(3) {
              width: 130px;
            }

            &:nth-child(4) {
              width: 160px;
            }

            &:nth-child(5) {
              width: 20%;
            }

            &:nth-child(6) {
              width: 15%;
            }

            &:nth-child(7) {
              width: 15%;
            }

            &:nth-child(8) {
              width: 15%;
            }

            &:nth-child(9) {
              width: 120px;
            }

            &:nth-child(10) {
              width: 70px;
            }
          }
        }
      }
    }
  }

  .table-wrapper.issue-list {
    &.horizontal-scroll {
      .table-box {
        table {
          min-width:140.7rem;
        }
      }
    }
    table {

      th,
      td {
        &:nth-child(1) {
          width: 90px;
        }

        &:nth-child(2) {
          width: 150px;
        }

        &:nth-child(3) {
          width: 150px;
        }

        &:nth-child(4) {
          width: 150px;
        }

        &:nth-child(5) {
          width: 150px;
        }

        &:nth-child(6) {
          width: 150px;
        }

        &:nth-child(7) {
          width: 150px;
        }

        &:nth-child(8) {
          width: 150px;
        }

        &:nth-child(9) {
          width: auto;
        }

        &:nth-child(10) {
          width: 90px;
        }

        &:nth-child(11) {
          width: 90px;
        }

      }
    }
  }
}

// .data-list-container

.td-depth2 {
  &::before {
    content: '';
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    background: url(../images/ic_group.svg) no-repeat center;
    vertical-align: top;
    margin: 0.1rem 0.5rem 0 0;
  }
}

.m-btn {
  display: none;
}

@media screen and (max-width:1280px) {
  .content-container {

    .content-box {
      .breadcrumb-for-400 {
        +.content-middle-box {
          padding-top: 0;
          border-radius: 0 0 1.4rem 1.4rem;
          border-top: none;
        }
      }

    }
  }

  .select-mode-options {
    display: block;

    &.expand {
      // height: 100%;
      height: auto;
      padding: 1.5rem;
      margin-bottom: 2rem;

      .left-side {
        display: block;
        margin-bottom: 2rem;
      }

      .right-side {
        display: block;

        .button-box {
          display: block;

          .btn {
            display: block;
            width: 100%;
            margin: 0;
            margin-bottom: 1rem;
          }
        }

        .group-tag-box {
          @include set-flex(row, center, center);
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }

  .breadcrumb-for-400 {
    display: block;
    width: 100%;
    padding: 3rem 2rem 1rem 2rem;
    border-radius: 1.4rem 1.4rem 0 0;
    border: 1px solid $color-border-03;
    border-bottom: none;
    background-color: $color-white;
  }

  .content-middle-box {
    border-radius: 0 0 1.4rem 1.4rem;
    border-top: none;
  }

  .select-mode-options  {
    .left-side {
      h2 {
        +.dropdown {
          margin: 1rem 0.4rem;
        }
      }
    }
  }

  .m-none {
    display: none;
  }

  .m-btn {
    display: block;
    width: 100%;
    margin: 0;
  }
}


@media screen and (max-width:767px) {
  .breadcrumb-for-400 {
    padding: 2rem;

    .field {
      margin-bottom: 0;

      .field-form {
        .dropdown {
          .btn-dropdown {
            font-size: 1.8rem;
          }
        }
      }
    }
  }

  .data-list-container {
    .data-list-top {
      display: block;
      padding: 0;
      margin-bottom: 0;

      .left-side {
        display: block;
        margin-bottom: 0.5rem;

        .btn {
          width: 100%;
          height: 2.8rem;
          margin-bottom: 1rem;
        }
      }

      .right-side {
        margin-bottom: 0.5rem;
      }
    }

    .grid {
      .element {
        .element-select {
          .left-side {
            .checkbox {
              label {
                top: 1rem;
              }
            }
          }
        }
      }
    }

    // .select-mode-options, .tab-panel { display:none; }
  }

  // .data-list-container

}

@media screen and (max-width:640px) {

  .wrapper {
    &.expand {
      .content-container {

        &.wide-padding {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .content-container {
      padding-left: 0;
      padding-right: 0;

      &.wide-padding {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .side-bar:after {
      display: none;
    }
  }

  .search-and-filter-box {

    .right-side{
      .btn {
        padding: 0 1.6rem;
  
        &.btn-info {
          margin-right: 0;
          margin-bottom: 1rem;
        }

        &.btn-download-excel {
          margin-top: 1rem;
        }
      }
    }
    
  }

}