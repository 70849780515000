@charset 'UTF-8';

// 1. Configuration and helpers
@import 'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

// Edit Site Container //
.content-container {
  &.expand {
    .edit-site-container {
      left:-3.6rem;
      &.active {
        left:27.1rem;
      }
    }    
  }
}

.edit-site-container {
  visibility: hidden;
  position:fixed;
  top:5.6rem;
  left:calc(-30.6rem - 8rem);
  bottom:0;
  width:30.6rem;
  box-sizing: content-box;
  background-color:$color-white;
  transition:left .25s;

  &.active {
    visibility: visible;
    left:8rem;
  }

  .header {
    @include set-flex(row, flex-start, center);

    .btn-back {
      overflow:hidden;
      width:5.4rem;
      height:5.4rem;
      border-radius: 0;
      border:none;
      background:asset-image('btn_back.svg') center no-repeat;
      text-indent: -9999px;
    }

    .title {
      @include set-font(2.1rem, bold, $color-gray-01);
    }
  }

  .scroll-box {
    overflow-y:auto;
    height:calc(100vh - 20rem);
    margin-top:5.2rem;
    margin-bottom:9.2rem;
    padding:2rem;
  }

  // .scroll-box {
    .edit-container {
      display: block;
      border-left:none;

      .edit-box {
        position:relative;
        margin-bottom:2rem;

        .edit-header {
          @include set-flex(row, space-between, center);
          width:100%;
          padding:1rem 0;
          border-bottom:1px solid $color-border-03;
          background-color: $color-white;
          
          h2 {
            @include set-font(1.65rem, 400, $color-gray-01, normal);
            margin-bottom:0;
          }

          .btn-add {
            width:7rem;
            height:2.8rem;
          }
        }

        .edit-middle {
          border:1px solid $color-border-03;
          .list {
            // height: 51rem;
            overflow-y: auto;
            &:hover {
              background-color: #f8f8f8;
            }
            li {
              padding: 0 1.6rem;
              cursor: pointer;

              &:last-child {
                .list-box {
                  border-bottom:none;
                }
              }

              .list-box {
                @include set-flex(row, space-between, center);
                padding: .8rem 0;
                border-bottom: 1px solid $color-border-03;

                .label {
                  @include set-font(1.4rem, 400, $color-gray-02, normal);
                }

                .btn {
                  overflow:hidden;
                  width:2.8rem;
                  height:2.8rem;
                  margin:0;
                  padding:0;
                  text-indent: -9999px;
                  border: 1px solid #777;

                  &:hover:not(:disabled) {
                    border: 1px solid #949494;
                    background-color: #eee;
                  }

                  &:active:not(:disabled) {
                    border: 3px solid $color-primary;
                    background-color: #ccc;
                  }

                  &.btn-group {
                    margin-right:.6rem;
                  }

                  &.btn-rename {
                    margin-right:.6rem;
                    &:disabled {
                      opacity:.5;
                      cursor: default;
                    }
                  }
                } // .btn
              }

              &.active {
                background-color: $color-hover;
                
                .label {
                  @include set-font(1.4rem, 700, $color-gray-01, normal);
                }  
              }
            } // li
          } // .list
        } // .edit-middle
      }
      .button-box {
        .btn.btn-small {
          min-width: auto;
        }
      }
    }
  // }

  > .button-box {
    @include set-flex(row, center, center);
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    padding:2rem;
    border-top:1px solid $color-border-03;
    background-color:$color-white;

    .btn {
      min-width:inherit;
      width:calc(50% - 0.3rem);

      &.btn-cancel {
        margin-right:0.3rem;
      }

      &.btn-done {
        margin-left:0.3rem;
      }
    }
  }
}

@media screen and (max-width:1280px) {
  .edit-site-container {
    left:-22.6rem;
    z-index: 33;
    border-right:1px solid $color-border-03;
    transition:left .25s;

    // &.active {
    //   visibility:visible;
    //   left: 8rem;
    // }

    .tab-group {
      display: none;
    }

    .edit-container {
      .edit-box {
        min-height: 23rem;
        .edit-middle .list {
          height: 23rem;
        }
      }
    } 
  }
}

@media screen and (max-width:991px) {
  .edit-site-container {
    .edit-container {
      .edit-box {
        width:100%;
        min-height: 0;
        margin:0;
        padding-bottom:3rem;
  
        &:last-child {
          margin-bottom:0;
        }
  
        &.edit-location:before,
        &.edit-shop:before {
          top:auto;
          left:50%;
          bottom:calc(100% - 0.5rem);
          transform: rotate(90deg);
        }
  
        .edit-middle .list {
          height: auto;
        }
      }
    }
  }
}

@media screen and (max-width:640px) {
  .edit-site-container {
    left:-100%;
    width:100%;

    &.active {
      left:0;
    }
  }
}