@charset 'UTF-8';

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

// Popup Edit Site //

.popup-wrapper .popup-edit-site {
  .popup-content {

    .field-account {

      .field-label {
        width: auto;
        margin-right:3.5rem;
      }
      .dropdown {
        width: 22.5rem;
      }
    } 

    .edit-container {
      @include set-flex(row, space-between, center);
      border:1px solid $color-border-03;
      border-left:none;

      .edit-box {
        position:relative;
        width:33.3%;
        min-height:42rem;
        border-left:1px solid $color-border-03;

        &.edit-shop, &.edit-location {
          &:before {
            content:'';
            display: block;
            position:absolute;
            top:50%;
            transform:translateY(-50%);
            right: 100%;
            width:15px;
            height:27px;
            background: asset-image('box_arrow.svg') center no-repeat;
          }
        }

        .edit-header {
          @include set-flex(row, space-between, center);
          width:100%;
          min-height: 5.4rem;
          padding:1rem 2.5rem;
          border-bottom:1px solid $color-border-03;
          background-color: $color-white;
          
          h2 {
            @include set-font(1.8rem, 500, $color-gray-01, normal);
            margin-bottom:0;
          }

          .btn-add {
            width:7rem;
          }
        }

        .edit-middle {
          height: 51rem;
          .text {
            height: 100%;
            @include set-flex(row, center, center);
            @include set-font(1.4rem, 400, #767676, normal);
          }
          .list {
            height: 100%;
            overflow-y: auto;
            &:hover {
              background-color: #f8f8f8;
            }
            li {
              position: relative;
              padding: 0 2.7rem;
              cursor: pointer;
              .list-box {
                @include set-flex(row, space-between, center);
                padding: .8rem 0;
                border-bottom: 1px solid $color-border-03;

                .label {
                  @include set-font(1.4rem, 400, $color-gray-02, normal);
                }

                .btn {
                  overflow:hidden;
                  width:2.8rem;
                  height:2.8rem;
                  margin:0;
                  padding:0;
                  text-indent: -9999px;
                  border: 1px solid #777;

                  &:hover:not(:disabled) {
                    border: 1px solid #949494;
                    background-color: #eee;
                  }

                  &:active:not(:disabled) {
                    border: 3px solid $color-primary;
                    background-color: #ccc;
                  }

                  &.btn-group {
                    margin-right:.6rem;
                  }

                  &.btn-rename {
                    margin-right:.6rem;
                    &:disabled {
                      opacity:.5;
                      cursor: default;
                    }
                  }
                } // .btn
              }

              &.active {
                background: $color-hover asset-image('sel_icon.svg') 1rem center no-repeat;
                
                .label {
                  @include set-font(1.4rem, 700, $color-gray-01, normal);
                  img {
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                  }
                }  
              }
            } // li
          } // .list
        } // .edit-middle
      }
      .button-box {
        .btn.btn-small {
          min-width: auto;
        }
      }
    }
  }

}
//150%
@media screen and (max-width:1280px) {
  .popup-wrapper .popup-edit-site {
    .popup-content {
      .edit-container {
        .edit-box {
          min-height: 23rem;
          .edit-middle .list {
            height: 23rem;
          }
        }
      } 
    }
  }
}
//200%
@media screen and (max-width:991px) {
  .popup-wrapper {
    .popup-edit-site {
      .popup-content {
        .edit-container {
          display: block;
          
          .edit-box {
            width:100%;
            min-height: 0;
            margin:0;
            padding-bottom:3rem;

            &:last-child {
              margin-bottom:0;
            }

            &.edit-location:before,
            &.edit-shop:before {
              top:auto;
              left:50%;
              bottom:calc(100% - 0.5rem);
              transform: rotate(90deg);
            }

            .edit-middle .list {
              height: auto;
            }
          }
        }
      }
    }
  }
}
//300%
@media screen and (max-width:767px) {
  .popup-wrapper {
    .popup-edit-site {
      &.large {
        .popup-content {
          max-height: calc(100% - 4rem);
        }
      }
    }
  }
}